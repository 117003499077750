// src/App.js

import React, { useState, useEffect } from 'react';
import { db } from './firebaseConfig';
import { collection, getDocs } from 'firebase/firestore';

function App() {
  const [collection1Data, setCollection1Data] = useState([]);
  const [collection2Data, setCollection2Data] = useState([]);

  useEffect(() => {
    const fetchCollection1Data = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'test'));
        const docs = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setCollection1Data(docs);
      } catch (error) {
        console.error("Error fetching collection1 data: ", error);
      }
    };

    const fetchCollection2Data = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'test2'));
        const docs = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setCollection2Data(docs);
      } catch (error) {
        console.error("Error fetching collection2 data: ", error);
      }
    };

    fetchCollection1Data();
    fetchCollection2Data();
  }, []);

  return (
    <div className="App">
      <h1>Firestore Data</h1>
      <h2>Collection 1 Data</h2>
      <ul>
        {collection1Data.map(item => (
          <li key={item.id}>{JSON.stringify(item)}</li>
        ))}
      </ul>
      <h2>Collection 2 Data</h2>
      <ul>
        {collection2Data.map(item => (
          <li key={item.id}>{JSON.stringify(item)}</li>
        ))}
      </ul>
    </div>
  );
}

export default App;
